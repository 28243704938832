<template>
  <div v-if="getItemError">
    <b-alert
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        Ошибка при получении данных сущности.
      </h4>
      <div class="alert-body">
        Не найдена сущность с этим идентификатором.
      </div>
    </b-alert>
  </div>
  <b-overlay
    v-else
    :show="loading"
    spinner-variant="primary"
  >
    <b-card>
      <b-row>
        <b-col>
          <h4>
            {{ item.name }}
          </h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table-simple
            :striped="true"
            :bordered="false"
            :borderless="true"
            :outlined="false"
            :small="false"
            :hover="false"
            :dark="false"
            :fixed="false"
            :responsive="true"
            class="mt-1"
          >
            <b-tbody>
              <b-tr>
                <b-th>
                  ID
                </b-th>
                <b-td>
                  {{ item.id }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Ключ
                </b-th>
                <b-td>
                  {{ item.key }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Описание
                </b-th>
                <b-td>
                  {{ item.description || '-' }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <!--      <b-row-->
      <!--        v-if="$store.getters['workingMode/checkPermission']('manage_warehouses')"-->
      <!--        class="mt-2"-->
      <!--      >-->
      <!--        <b-col>-->
      <!--          <div class="d-flex flex-wrap">-->
      <!--            <b-button-->
      <!--              v-if="item.id"-->
      <!--              :to="{ name: 'warehouses.edit', params: { id: item.id } }"-->
      <!--              variant="primary"-->
      <!--            >-->
      <!--              Изменить-->
      <!--            </b-button>-->
      <!--            <b-button-->
      <!--              variant="outline-danger"-->
      <!--              class="ml-1"-->
      <!--              @click="deleteItem(item)"-->
      <!--            >-->
      <!--              Удалить-->
      <!--            </b-button>-->
      <!--          </div>-->
      <!--        </b-col>-->
      <!--      </b-row>-->
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BOverlay, BButton, BAlert, BLink, BBadge, BTabs, BTab,
  BFormGroup, BFormInput, BFormTextarea, BCardHeader, BCardTitle, BTable, BFormCheckbox,
  BTableSimple, BTbody, BTr, BTd, BTh, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import get from 'lodash/get'
import { getCurrentInstance, ref } from 'vue'

import {
  getEntity as getEntityApi, deleteEntity as deleteEntityApi,
} from '@/services/main-api/websites/externals/entities'

import useCrudShow from '@/composables/useCrudShow'

export default {
  name: 'ShowItemView',
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    BButton,
    BAlert,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCardHeader,
    BCardTitle,
    BTable,
    BFormCheckbox,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BTh,
    BListGroup,
    BListGroupItem,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props) {
    const instance = getCurrentInstance().proxy
    const getItemError = ref(false)

    const getItem = async () => {
      try {
        const res = await getEntityApi({ id: props.id })
        instance.item = res.data.data
      } catch (e) {
        instance.item = {}
        instance.getItemError = true
      }
    }

    const {
      item,
      loading,
      deleteItem,
    } = useCrudShow({
      item: {
        id: null,
        name: '',
        key: '',
        description: '',
      },
      getItem,
      deleteEntity: i => deleteEntityApi(i.id),
      getQuestionTextBeforeDeletion: i => `Сущность <strong>${i.name}</strong> будет удалена`,
      getTextAfterDeletion: () => 'Сущность была удален.',
      runAfterDeletion: () => {
        instance.$router.go(-1)
      },
    })

    return {
      item,
      loading,

      getItemError,
      deleteItem,

      get,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
